@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #f7fff7;
  overflow: hidden;
}
.content_body {
  background-color: rgb(223, 255, 216);
  width: 100%;
  height: 90%;
  overflow: scroll;
}
.app {
  display: flex;
  position: relative;
}
.menu-bars {
  text-decoration: none;
  color: #000;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
